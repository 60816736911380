export function getKsUrls({
  dbUrl,
  apiUrl,
}: {
  dbUrl: string | undefined;
  apiUrl: string | undefined;
}) {
  const { origin: dbOrigin } = new URL(dbUrl || 'https://db.knapsack.cloud');
  const dbGqlEndpoint = new URL(
    '/v1/graphql',
    dbOrigin,
  ).toString() as `${string}/graphql`;

  const dbGqlWsEndpoint = new URL(dbGqlEndpoint);
  dbGqlWsEndpoint.protocol = 'wss:';

  const { origin: apiOrigin } = new URL(apiUrl || 'https://api.knapsack.cloud');
  const apiGqlEndpoint = new URL(
    '/graphql',
    apiOrigin,
  ).toString() as `${string}/graphql`;

  return {
    /**
     * Base URL for Knapsack DB (Hasura)
     * @default 'https://db.knapsack.cloud'
     */
    dbOrigin,
    /**
     * App DB GraphQL endpoint for connecting to Hasura GraphQL.
     * @default 'https://db.knapsack.cloud/v1/graphql'
     */
    dbGqlEndpoint,
    /**
     * App DB GraphQL WebSocket endpoint for connecting to Hasura GraphQL.
     * @default 'wss://db.knapsack.cloud/v1/graphql'
     */
    dbGqlWsEndpoint: dbGqlWsEndpoint.toString() as `wss:${string}/graphql`,
    /**
     * App API GraphQL endpoint for connecting to Api Server GraphQL.
     * @default 'https://api.knapsack.cloud/graphql'
     */
    apiGqlEndpoint,
    /**
     * Base URL for Knapsack API
     * @default 'https://api.knapsack.cloud'
     */
    apiOrigin,
  };
}
