import { GraphQLClient, ClientError } from 'graphql-request';
import type {
  KSGraphQLFormattedError,
  KsGqlErrorExtensionCodes,
} from '@knapsack/api-server/types';
import { getSdk } from './temp/graphql-request';
// export * as Dom from 'graphql-request/dist/types.dom';
export * from './temp/graphql-request';
export { ClientError as GraphqlClientError };

export class KsAppApiGqlClientError extends Error {
  errorCode: KsGqlErrorExtensionCodes;
  gqlErrors: KSGraphQLFormattedError[];
  override cause: Error;
  constructor(
    message: string,
    {
      cause,
      errorCode,
      gqlErrors,
    }: {
      cause: Error;
      errorCode: KsGqlErrorExtensionCodes;
      gqlErrors: KSGraphQLFormattedError[];
    },
  ) {
    super(message);
    this.name = 'KsAppApiGqlClientError';
    this.cause = cause;
    this.errorCode = errorCode;
    this.gqlErrors = gqlErrors;
  }
}

export type AppApiGqlClient = ReturnType<typeof getSdk>;

/**
 * Uses the `graphql-request` library that wraps `fetch` to make requests to Hasura GraphQL.
 */
export function createAppApiGqlClient({
  getHeaders,
  serverUrl,
  logError,
}: {
  getHeaders: () => Promise<Record<string, string>>;
  serverUrl: `${string}/graphql`;
  logError?: (error: KsAppApiGqlClientError | Error) => void;
}): AppApiGqlClient {
  const url = new URL(serverUrl);
  if (!url.pathname.endsWith('/graphql')) {
    throw new Error(`serverUrl must end with /graphql, got ${serverUrl}`);
  }
  const client = new GraphQLClient(url.toString());

  return getSdk(client, async (action) => {
    const headers = await getHeaders();
    Object.entries(headers).forEach(([key, value]) => {
      client.setHeader(key, value);
    });
    try {
      const results = await action();
      return results;
    } catch (err) {
      if (!(err instanceof ClientError)) {
        throw err;
      }
      const { errors = [] } = err.response;
      if (errors.length === 0) {
        logError?.(err);
        throw err;
      }
      // errors are almost always an array of length 1
      const [{ extensions }] = errors;
      const message = errors.map((e) => e.message).join('\n');
      const error = new KsAppApiGqlClientError(message, {
        cause: err,
        // @ts-expect-error this is fine
        errorCode: extensions?.code,
        // @ts-expect-error this is fine
        gqlErrors: errors,
      });
      logError?.(error);
      throw error;
    }
  });
}
