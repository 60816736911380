'use client';

import React from 'react';
import {
  TextBody,
  TextTitle,
  vars,
  ButtonGroup,
  ButtonPrimary,
  ButtonSecondary,
  Flex,
  Banner,
  type BannerProps,
} from '@knapsack/toby';
import { openUrlInTab } from '@/utils/url-utils';
import Image from 'next/image';
import { KnapsackWordmark } from '../../components/knapsack-logo/knapsack-logo';
import authError from '../../assets/illustrations/error/auth-error.image.svg';
import siteIdNotFound from '../../assets/illustrations/error/site-id-not-found.image.svg';
import noAccess from '../../assets/illustrations/error/no-access.image.svg';
import noConnection from '../../assets/illustrations/error/no-connection.image.svg';
import notConfigured from '../../assets/illustrations/status/not-configured.image.svg';
import privateWorkspace from '../../assets/illustrations/error/private-workspace.image.svg';
import updateRequired from '../../assets/illustrations/error/update-required.image.svg';
import accessDenied from '../../assets/illustrations/error/access-denied.image.svg';
import noSites from '../../assets/illustrations/error/no-sites.image.svg';
import statusDeleted from '../../assets/illustrations/status/status-deleted.image.svg';
import statusPublished from '../../assets/illustrations/status/status-published.image.svg';
import unknown from '../../assets/illustrations/error/unknown.image.svg';
import { errorPage, errorPageContent, errorPageText } from './error-page.css';
import { sendAppEvent, useAppStateMatches } from '../xstate';

type Props = {
  title: string;
  message?: React.ReactNode;
  testId?: string;
  banner?: BannerProps;
  actions?: {
    disabled?: boolean;
    // @todo add proper `key` prop - currently uses `label`
    label: string;
    /** Either link (string) or function */
    onClick:
      | string
      | ((
          event:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.KeyboardEvent<HTMLButtonElement>,
        ) => void);
    loading?: boolean;
  }[];
  graphic?:
    | 'auth-error'
    | 'site-id-not-found'
    | 'no-access'
    | 'no-connection'
    | 'private-workspace'
    | 'update-required'
    | 'access-denied'
    | 'no-sites'
    | 'not-configured'
    | 'status-deleted'
    | 'status-published'
    | 'unknown';
  hideSignOut?: boolean;
  hideBranding?: boolean;
};

const Graphic = ({ graphic }: { graphic: Props['graphic'] }) => {
  switch (graphic) {
    case 'auth-error':
      return <Image src={authError} alt="auth-error" width={400} />;
    case 'site-id-not-found':
      return <Image src={siteIdNotFound} alt="site-id-not-found" width={400} />;
    case 'no-access':
      return <Image src={noAccess} alt="no-access" width={400} />;
    case 'no-connection':
      return <Image src={noConnection} alt="no-connection" width={400} />;
    case 'not-configured':
      return <Image src={notConfigured} alt="not-configured" width={400} />;
    case 'private-workspace':
      return (
        <Image src={privateWorkspace} alt="private-workspace" width={400} />
      );
    case 'update-required':
      return <Image src={updateRequired} alt="update-required" width={400} />;
    case 'access-denied':
      return <Image src={accessDenied} alt="access-denied" width={400} />;
    case 'no-sites':
      return <Image src={noSites} alt="no-sites" width={400} />;
    case 'status-deleted':
      return <Image src={statusDeleted} alt="status-deleted" width={400} />;
    case 'status-published':
      return <Image src={statusPublished} alt="status-published" width={400} />;
    case 'unknown':
      return <Image src={unknown} alt="unknown" width={400} />;
    default: {
      const _exhaustiveCheck: never = graphic;
      return <Image src={unknown} alt="unknown" width={400} />;
    }
  }
};

export const ErrorPage = ({
  graphic,
  title,
  message,
  actions,
  testId,
  banner,
  hideSignOut,
  hideBranding,
}: Props) => {
  const isLoggedIn = useAppStateMatches('user.loggedIn');

  return (
    <main className={errorPage} data-test-id={testId}>
      <div className={errorPageContent}>
        <Graphic graphic={graphic} />
        <div className={errorPageText}>
          {!hideBranding && (
            <div
              style={{
                color: vars.color.text.subtle,
                marginBottom: vars.spacing.component.large,
              }}
            >
              <KnapsackWordmark />
            </div>
          )}
          <div data-test-id="errorPageMessage">
            <Flex direction="column" gap="large" align="stretch">
              <TextTitle size="small" spacing="none">
                {title}
              </TextTitle>
              {message && React.isValidElement(message) && message}
              {message && !React.isValidElement(message) && (
                <TextBody size="large">{message}</TextBody>
              )}
              {banner && <Banner {...banner} />}
              <ButtonGroup justify="start">
                {actions?.map(({ label, onClick, loading, disabled }, i) => {
                  const onTrigger =
                    typeof onClick === 'string'
                      ? () => openUrlInTab(onClick)
                      : onClick;
                  return i === 0 ? (
                    <ButtonPrimary
                      key={label}
                      disabled={disabled}
                      label={label}
                      onTrigger={({ event }) => onTrigger(event)}
                      loading={loading}
                    />
                  ) : (
                    <ButtonSecondary
                      key={label}
                      disabled={disabled}
                      onTrigger={({ event }) => onTrigger(event)}
                      label={label}
                      loading={loading}
                    />
                  );
                })}
                {!hideSignOut && isLoggedIn && (
                  <ButtonSecondary
                    label="Sign Out"
                    onTrigger={() => sendAppEvent({ type: 'user.signOut' })}
                  />
                )}
              </ButtonGroup>
            </Flex>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ErrorPage;
