import { createAppApiGqlClient } from '@knapsack/app-api-gql-client';
import { captureException } from '@/utils/sentry';
import { getHttpHeaders as getHeaders } from './http-headers-utils';
import { ksUrls } from './ks-urls';

export * from '@knapsack/app-api-gql-client';

export const appApiGql = createAppApiGqlClient({
  serverUrl: ksUrls.apiGqlEndpoint,
  getHeaders,
  logError: (error) => captureException(error),
});
