const DOCS_BASE_URL = 'https://docs.knapsack.cloud/site/ks-docs/latest';

export const DOCS_URLS = {
  HOME: DOCS_BASE_URL,

  // Figma
  CONNECTING_DESIGN_SOURCES: `${DOCS_BASE_URL}/pages/figma-design-source`,
  FIGMA_PLUGIN: `${DOCS_BASE_URL}/pages/figma-plugin`,

  // General
  ENVIRONMENTS: `${DOCS_BASE_URL}/pages/environments`,
  RUNNING_KNAPSACK_LOCALLY: `${DOCS_BASE_URL}/pages/running-knapsack-locally`,
  UPDATING_KNAPSACK: `${DOCS_BASE_URL}/pages/updating-knapsack`,

  // Patterns
  ADDING_TEMPLATES: `${DOCS_BASE_URL}/pages/adding-templates`,
  CONNECTING_CODE: `${DOCS_BASE_URL}/pages/connecting-code`,

  // Prototyping
  PROTOTYPING: `${DOCS_BASE_URL}/pages/prototyping`,

  // Tokens and Variables
  IMPORTING_TOKENS: `${DOCS_BASE_URL}/pages/importing-tokens`,
  TOKENS_AND_VARIABLES: `${DOCS_BASE_URL}/pages/design-tokens-and-variables`,
} as const;
