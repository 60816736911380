type BroadcastChannelMessage =
  | {
      type: 'broadcast.user.loggedIn';
    }
  | { type: 'broadcast.user.loggedOut' };

/**
 * A TypeSafe `BroadcastChannel` for sending and receiving data changes
 * This allows a user to have multiple browser windows open propogated changes
 * @see https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel
 */
class UserChangesBroadcastChannel {
  broadcastChannel = new BroadcastChannel('knapsack-user-changes');

  /**
   * @returns a cleanup function that will remove the listener
   */
  addMessageListener(
    eventType: BroadcastChannelMessage['type'],
    listener: () => void,
  ): () => void {
    const abortController = new AbortController();
    this.broadcastChannel.addEventListener(
      'message',
      (event) => {
        // console.log('message event', event);
        if (event.data?.type === eventType) {
          listener();
        }
      },
      {
        signal: abortController.signal,
      },
    );

    return () => abortController.abort();
  }

  postMessage(message: BroadcastChannelMessage) {
    // console.log('posting message', message);
    this.broadcastChannel.postMessage(JSON.parse(JSON.stringify(message)));
  }
}

export const userChangesBroadcastChannel = new UserChangesBroadcastChannel();
