type AuthCallbackUrlInfo =
  | {
      type: 'implicit-grant';
      accessToken: string;
      // idToken: string;
      // expiresIn: number;
      // scope: string;
      // tokenType: 'Bearer';
    }
  | {
      type: 'authorization-grant.success';
      code: string;
      state: string;
    }
  | {
      type: 'authorization-grant.error';
      error: string;
      errorDescription: string;
    }
  | {
      type: 'none';
    };

export function parseAuthCallbackUrl(url: URL): AuthCallbackUrlInfo {
  if (url.hash) {
    // removes leading `#`
    const params = new URLSearchParams(url.hash.slice(1));
    if (params.has('access_token')) {
      return {
        type: 'implicit-grant',
        accessToken: params.get('access_token')!,
      };
    }
  }
  if (url.searchParams.has('code') && url.searchParams.has('state')) {
    return {
      type: 'authorization-grant.success',
      code: url.searchParams.get('code')!,
      state: url.searchParams.get('state')!,
    };
  }
  if (
    url.searchParams.has('error') &&
    url.searchParams.has('error_description')
  ) {
    return {
      type: 'authorization-grant.error',
      error: url.searchParams.get('error')!,
      errorDescription: url.searchParams.get('error_description')!,
    };
  }
  return { type: 'none' };
}

export function redactAuthCallbackUrl(url: string): string {
  const u = new URL(url);
  if (!u.hash) return url;
  // removes leading `#`
  const hash = u.hash.slice(1);
  if (!hash) return url;
  // implicit grant uses hash to store info in the same format as query params, so we can use `URLSearchParams`
  const params = new URLSearchParams(hash);
  if (!params.has('access_token')) return url;
  params.set('access_token', 'REDACTED');
  u.hash = `#${params.toString()}`;
  return u.toString();
}
