'use client';

import type { LogMetadata } from '@knapsack/logger';
import { datadogLogs as dd } from '@datadog/browser-logs'; // https://docs.datadoghq.com/logs/log_collection/javascript/
import { isBrowser } from '@knapsack/utils';
import { redactAuthCallbackUrl } from '@/domains/users/utils/oauth-url-utils';

const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;

if (isBrowser && clientToken) {
  dd.init({
    site: 'datadoghq.com',
    service: 'app-ui',
    clientToken,
    env: process.env.NEXT_PUBLIC_ENV,
    version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100, // percent
    telemetrySampleRate: 0, // percent of events to send to Datadog for their telemetry tracking
    // should give us info in login popup. might need to clear context in situations though
    storeContextsAcrossPages: true,

    /**
     * Return `false` to prevent the event from being sent to Datadog.
     * Can mutate the `event` object safely
     * Note that `event.http.url` is the URL of the HTTP request that triggered the event
     * Note that `event.view.url` is the URL of the current page
     * Note that `event.view.referrer` is the URL of the page that referred to the current page
     */
    beforeSend(event, _context) {
      if (event.http?.url) {
        const u = new URL(event.http.url);
        if (
          ['localhost', '127.0.0.1', 'app.launchdarkly.com'].includes(
            u.hostname,
          )
        ) {
          return false;
        }
        if (
          u.hostname === window.location.hostname &&
          u.pathname === '/monitoring'
        ) {
          return false;
        }
        if (u.searchParams.has('isOkToFail')) return false;
      }

      if (event.http?.url?.includes('access_token')) {
        event.http.url = redactAuthCallbackUrl(event.http.url);
      }
      if (event.view?.url?.includes('access_token')) {
        event.view.url = redactAuthCallbackUrl(event.view.url);
      }
      if (event.view?.referrer?.includes('access_token')) {
        event.view.referrer = redactAuthCallbackUrl(event.view.referrer);
      }

      return true;
    },
  });
}

export const log = {
  info: (message: string, metadata?: LogMetadata) => {
    console.log(message, metadata);
    dd.logger.info(message, metadata);
  },
  warn: (message: string, metadata?: LogMetadata) => {
    console.warn(message, metadata);
    dd.logger.warn(message, metadata);
  },
  error: (message: string, metadata?: LogMetadata) => {
    console.error(message, metadata);
    dd.logger.error(message, metadata);
  },
};

export const logError = (error: Error) => {
  console.error(error);
  dd.logger.error(error.message, { error }, error);
};

export const clearUser = () => dd.clearUser();

export const setUserId = (userId: string) => dd.setUser({ id: userId });

export const setGlobalContext = (context: LogMetadata) =>
  dd.setGlobalContext(context);

export const setGlobalContextProperty = <K extends keyof LogMetadata>(
  key: K,
  value: LogMetadata[K],
) => dd.setGlobalContextProperty(key, value);
