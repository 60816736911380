/**
 * The roles here are duplicates of the roles in the database.
 * It needs to be here, in the types package, so it can be used in all packages.
 * If we pull it in from `@knapsack/hasura-gql-client`, which is the source, we get a dependency cycle. So we do it this way.
 * If the roles change in the database, we need to change them here too. We will get a build error if we don't (see code in `@knapsack/hasura-gql-client`).
 */

export const rolesById = {
  ADMIN: 'ADMIN',
  ANONYMOUS: 'ANONYMOUS',
  CONTRIBUTOR: 'CONTRIBUTOR',
  EDITOR: 'EDITOR',
  MANAGER: 'MANAGER',
  VIEWER: 'VIEWER',
  CONSUMER: 'CONSUMER',
} as const;
export const roles = Object.values(rolesById);
export type Role = keyof typeof rolesById;
