import { useEffect, useRef, useState } from 'react';
import {
  Banner,
  ButtonGroup,
  ButtonPrimary,
  ButtonTertiary,
  FieldTextInput,
  Image,
  FloatingAction,
  FieldSelect,
  componentSpacing,
  Spinner,
} from '@knapsack/toby';
import { uploadImage } from '@/services/image-upload';
import { sendAppEvent, uiService, useAppCtxSelector } from '@/core';
import { useMutation } from '@tanstack/react-query';
import { hasuraGql } from '@/services/hasura-client';
import type { KscUser } from '@/types';
import { isCypress } from '@/utils/constants';
import type { Responsibilities_Enum } from '@knapsack/hasura-gql-client';
import { useForm, Controller } from 'react-hook-form';
import { ConnectedAccountsMenu } from './connected-accounts/connected-accounts-menu';
import {
  profilePhoto,
  profilePhotoImage,
  profilePhotoRemove,
} from './profile-form.css';

const responsibilities = {
  DESIGNER: 'DESIGNER',
  ENGINEER: 'ENGINEER',
  MARKETING: 'MARKETING',
  OTHER: 'OTHER',
  PRODUCT: 'PRODUCT',
} satisfies Record<Responsibilities_Enum, string>;

export type ProfileFormValues = {
  displayName: string;
  profilePic?: string;
  responsibilityId?: Responsibilities_Enum;
  email: string;
};

export const ProfileForm = ({
  onIsFormDirty,
}: {
  onIsFormDirty?: (isDirty: boolean) => void;
}) => {
  const user = useAppCtxSelector((ctx) => ctx.user);
  const initialData = {
    email: user?.email,
    displayName: user?.info?.displayName,
    responsibilityId: user?.info?.responsibilityId,
    profilePic: user?.info?.profilePic,
  };

  const {
    handleSubmit,
    control,
    formState: {
      errors,
      isDirty,
      isSubmitting: isFormSubmitting,
      isSubmitSuccessful,
      isValid,
    },
    reset,
    setError,
  } = useForm<ProfileFormValues>({
    values: initialData,
    mode: 'onChange',
  });

  useEffect(() => {
    onIsFormDirty?.(isDirty);
  }, [isDirty, onIsFormDirty]);

  const userPhotoInDb = initialData.profilePic;
  const [profilePicture, setProfilePicture] = useState(userPhotoInDb);

  const [displayName, setUserDisplayName] = useState(initialData.displayName);
  const userPhotoInBrowser = useRef('');
  const previousProfilePhoto = useRef(null); // backup photo if changes are reverted
  const skipNextImgixUpload = useRef(false);
  const [isUpdatingPhoto, setIsUpdatingPhoto] = useState(false);
  useEffect(() => {
    if (userPhotoInDb === undefined) return;
    if (previousProfilePhoto.current === null) {
      previousProfilePhoto.current = userPhotoInDb;
    }
  }, [userPhotoInDb]);

  useEffect(() => {
    if (userPhotoInDb) {
      if (userPhotoInDb !== profilePicture) {
        if (userPhotoInBrowser.current === '') {
          skipNextImgixUpload.current = true;
          userPhotoInBrowser.current = userPhotoInDb;
          setProfilePicture(userPhotoInDb);
        } else {
          if (userPhotoInDb !== '') {
            skipNextImgixUpload.current = true;
          }

          userPhotoInBrowser.current = userPhotoInDb;
          setProfilePicture(userPhotoInDb);
        }
      }
      // photo removed in DB removing from client
    } else if (profilePicture !== '' && userPhotoInDb === '') {
      skipNextImgixUpload.current = true;
      userPhotoInBrowser.current = '';
      setProfilePicture('');
      // still waiting for DB data
    } else {
      skipNextImgixUpload.current = false;
    }
  }, [userPhotoInBrowser, userPhotoInDb, profilePicture]);

  const { mutateAsync: updateUserInfo, isPending: isMutationPending } =
    useMutation({
      mutationFn: hasuraGql.UpdateUserInfo,
    });

  /**
   * There is weird bug where even though `handleSubmit` is not finished `isFormSubmitting` is `false` after a little bit.
   * Doing this for now to make sure all mutations are done before setting `isSubmitting` to `false`.
   */
  const isSubmitting = isFormSubmitting || isMutationPending;

  async function onSubmit(values: ProfileFormValues) {
    setUserDisplayName(values.displayName);
    try {
      if (!user?.userId) {
        throw new Error('User ID is required');
      }
      const userInfo = {
        displayName: values.displayName,
        responsibilityId: values.responsibilityId,
        profilePic: values.profilePic,
      } satisfies KscUser;
      await updateUserInfo({
        userId: user.userId,
        ...userInfo,
      });
      sendAppEvent({
        type: 'user.infoChanged',
        info: userInfo,
      });
      uiService.send({
        type: 'modal.triggerClose',
      });
    } catch (err) {
      console.error(err);
      setError('root.serverError', {
        message: `Failed to update profile: ${err.message}`,
      });
    }
  }

  useEffect(() => {
    if (!displayName && initialData.displayName !== '') {
      setUserDisplayName(initialData.displayName);
    }
  }, [displayName, initialData.displayName]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {Object.entries(errors).map(([key, value]) => {
          if (key === 'root' && typeof value?.message !== 'string') return null;
          return (
            <Banner type="error" key={key}>
              {key} - {value.message}
            </Banner>
          );
        })}
        {errors?.root?.serverError && (
          <Banner type="error" title={errors.root.serverError.message} />
        )}
        {isSubmitting && <Banner type="info" title="Submitting..." />}
        {isSubmitSuccessful && <Banner type="success" title="Submitted!" />}
        <Controller
          name="profilePic"
          control={control}
          render={({ field: { value, onChange } }) =>
            isUpdatingPhoto ? (
              <Spinner size="medium" />
            ) : (
              <div className={profilePhoto}>
                <Image
                  ariaLabel="Upload profile picture"
                  className={profilePhotoImage}
                  imgSrc={value}
                  size="xsmall"
                  circle
                  onTrigger={async (fileToUpload) => {
                    setIsUpdatingPhoto(true);
                    const result = await uploadImage({
                      file: fileToUpload,
                      isUserSpecific: true,
                    });
                    if (!result.ok) {
                      // setIsUpdatingPhoto(false);

                      throw new Error(`Could not upload image.`);
                    }
                    const uploadedImagePath = result.data.publicPath;
                    setIsUpdatingPhoto(false);
                    onChange(uploadedImagePath);
                  }}
                />

                {value !== '' && (
                  <FloatingAction
                    label="Remove profile photo"
                    className={profilePhotoRemove}
                    icon="close"
                    size="small"
                    color="subtle"
                    onTrigger={() => {
                      onChange('');
                    }}
                  />
                )}
              </div>
            )
          }
        />
        <Controller
          name="displayName"
          control={control}
          rules={{
            required: 'Required',
            // min: 3,
          }}
          render={({ field, fieldState }) => (
            <FieldTextInput
              {...field}
              label="Display Name"
              size="large"
              required
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="responsibilityId"
          control={control}
          rules={{
            required: 'Please set a responsibility',
          }}
          render={({ field, fieldState }) => (
            <FieldSelect
              {...field}
              label="Responsibility"
              helperText="What is your main role at your organization?"
              size="large"
              error={fieldState.error?.message}
              placeholder="Choose a responsibility..."
              options={[
                ...Object.entries(responsibilities).map(([value, label]) => {
                  return { value, label };
                }),
              ]}
              onChange={field.onChange}
              required
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <FieldTextInput
              {...field}
              label="Email"
              size="large"
              readOnly
              disabled
              error={fieldState.error?.message}
            />
          )}
        />

        <ButtonGroup className={componentSpacing({ marginBlock: 'xlarge' })}>
          <ButtonTertiary
            label="Discard Changes"
            onTrigger={() => {
              reset();
            }}
            disabled={!isDirty || isSubmitting}
          />
          <ButtonPrimary
            label={isSubmitting ? 'Saving...' : 'Save Changes'}
            onTrigger={() => {}}
            type="submit"
            disabled={!isDirty || isSubmitting || !isValid}
          />
        </ButtonGroup>
      </form>
      {/* Currently failing on Cypress due to the server not having the correct gql schema yet */}
      {/* @TODO: update the e2e testing to include the connected accounts section */}
      {/* https://linear.app/knapsack/issue/KSP-4516/setup-e2e-testing-for-connected-account-section */}
      {!isCypress() && <ConnectedAccountsMenu userId={user?.userId} />}
    </>
  );
};

export default ProfileForm;
