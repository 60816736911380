import type { CSSProperties } from 'react';
import type { JsonSchemaObject } from '../json-schema';
import { RendererId } from '../renderers';
import { isObject } from '../type-utils';
import type { BlockConfig } from './blocks';
import type { ContentMeta, KnapsackImage } from '../misc';
import type { Page, PageTab } from './page';
import { TokenSrcValueInfo } from './design-tokens-types/token-src-value-info';

export interface KsStatus {
  id: string;
  title: string;
  color?: string;
}

export interface KsStatusSet {
  id: string;
  title: string;
  statuses: KsStatus[];
}

/**
 * Used by template renderers in addition to `path`
 * Twig: `@components/button.twig`
 * Web Components: `my-button` => `<my-button>`
 * React: a named export of the file if not `default`
 * @todo Replace this with `rendererOptions`
 */
type TemplateAlias = string;

export type SlottedTemplateDemo = {
  type: 'template-demo';
  /** @deprecated */
  patternId: string;
  /** @deprecated */
  templateId: string;
  /**
   * The demo referenced by `demoId` is inlined here before sending to server to
   * render, but is never stored in the json data. If this is present, it is
   * used instead of the demo referenced by `demoId`. This is done to ensure
   * server has all the latest data for a template demo by ensuring it includes
   * it's slotted children's data.
   * @see {inlineDemoData}
   */
  demo?: DataDemo;
  demoId: DemoBase['id'];
};

/**
 * i.e. Given a React Component, `Button`, this set to null/false would be
 * `<Button>` and set to true it would just be a reference to it: `Button`
 * @see {KsSlotInfo['isTemplateReference']}
 */
export type SlottedTemplateReference = {
  type: 'template-reference';
  patternId: string;
  templateId: string;
};

export type SlottedText = {
  type: 'text';
  text: string;
};

export type SlottedData =
  | SlottedText
  | SlottedTemplateDemo
  | SlottedTemplateReference;

/** @deprecated - just check `type` */
export const isSlottedText = (
  slottedData: unknown,
): slottedData is SlottedText =>
  isObject(slottedData) && slottedData.type === 'text';

/** @deprecated - just check `type` */
export function isSlottedTemplateDemo(
  slottedData: unknown,
): slottedData is SlottedTemplateDemo {
  return isObject(slottedData) && slottedData.type === 'template-demo';
}

/** @deprecated - just check `type` */
export function isSlottedTemplateReference(
  slottedData: unknown,
): slottedData is SlottedTemplateReference {
  return isObject(slottedData) && slottedData.type === 'template-reference';
}

/**
 * Collection ID: Mode ID
 */
export type ThemeDefinition = {
  [collectionId: string]: string;
};

export type SlotOptions = {
  /**
   * @default 'div'
   */
  wrappingElementName?: string;
  cssClasses?: string[];
  align?: {
    value:
      | 'top-left'
      | 'top-center'
      | 'top-right'
      | 'left'
      | 'center'
      | 'right'
      | 'bottom-left'
      | 'bottom-center'
      | 'bottom-right';
  };
  layout?: {
    value: 'horizontal' | 'vertical' | 'wrap';
  };
  gap?: {
    value: TokenSrcValueInfo<'dimension'>;
  };
  paddingHorizontal?: {
    value: TokenSrcValueInfo<'dimension'>[];
  };
  paddingVertical?: {
    value: TokenSrcValueInfo<'dimension'>[];
  };
  /**
   * Collection ID: Mode ID
   */
  theme?: ThemeDefinition;
};

/**
 * These are created from {SlotOptions}
 * Only available in App Client for rendering - this is NOT saved in App Client Data
 */
export type SlotOptionsComputed = {
  styles: CSSProperties;
  cssClasses: string[];
  /**
   * @default 'div'
   */
  wrappingElementName: string;
  /**
   * can't be `style` or `class` or `className`
   */
  attributes: {
    [attributeName: string]: string;
  };
};

export type KnapsackTemplateData = {
  /**
   * @todo Ideal would be `{ [key: string]: string | boolean | number }`
   */
  props: {
    [prop: string]: any;
  };
  slots?: {
    [slotName: string]: Array<SlottedData>;
  };
  /**
   * Settings from users are stored here and saved in App Client Data
   * This is used to dynamically create `slotsOptionsComputed`
   */
  slotsOptions?: {
    [slotName: string]: SlotOptions;
  };
  /**
   * Use `slotsOptions` to set & save styles on slotted children
   * Never set nor stored in App Client Data that gets saved
   * This is only inlined in the demo data for rendering so the App Client can
   * apply the styles to the slotted children
   * @see {inlineDemoData}
   */
  slotsOptionsComputed?: {
    [slotName: string]: SlotOptionsComputed;
  };
};

type DemoType = 'data' | 'template';

type DemoBase = {
  id: string;
  title: string;
  description?: string;
  /**
   * Would override what was on the template
   */
  assetSetId?: string;
  width?: number;
  height?: number;
  type: DemoType;
  /** Collection ID: Mode ID */
  theme?: ThemeDefinition;
  meta: ContentMeta;
};

/**
 * Shows usage
 */
export type DataDemo = DemoBase & {
  type: 'data';
  patternId: string;
  templateId: string;
  data: KnapsackTemplateData;
};

/**
 * Shows source code
 */
export type TemplateDemo = DemoBase & {
  type: 'template';
  templateInfo: {
    alias?: TemplateAlias;
    path?: string;
  };
};

export type Demo = TemplateDemo | DataDemo;

export type RenderDataDemo = Demo & {
  inlineStyles?: string;
  bodyAttributes?: Record<string, string>;
};

/** @deprecated */
export enum StageLayouts {
  portrait = 'portrait',
  landscape = 'landscape',
}

export function isDataDemo(demo: TemplateDemo | DataDemo): demo is DataDemo {
  return demo?.type === 'data';
}

export function isTemplateDemo(demo: Demo): demo is TemplateDemo {
  return demo?.type === 'template';
}

export type KsSlotInfo = {
  title: string;
  description?: string;
  disallowText?: boolean;
  isRequired?: boolean;
  allowOnlyOne?: boolean;
  /**
   * i.e. Given a React Component, `Button`, this set to null/false would be
   * `<Button>` and set to true it would just be a reference to it: `Button`
   * @see {SlottedTemplateReference}
   */
  isTemplateReference?: boolean;
  allowedPatternIds?: string[];
};

/**
 * Supersedes `schema`
 */
export type KsTemplateSpec = {
  /**
   * Attempt to create the props & slots spec by reading the source file.
   * Prevents ability to use spec editor.
   *
   * If `true`, then uses the template `alias` & `path` to find the file.
   *
   * If a string, then that file path is used in place of the source file.
   *
   * Scenario: the `path` is used to point to a compiled file w types stripped
   * and this is used to point to either the source file w types or the compiled
   * `.d.ts` file. Currently only React is supported. The file extension
   * determines if TypeScript (ts/tsx) or PropTypes (js/jsx) is used for
   * inference.
   */
  isInferred?: boolean | string;
  /**
   * JSON Schema defining the serializable data passed in
   * The classic, formerly `schema`
   */
  props?: JsonSchemaObject;
  /**
   * Child component slots
   */
  propOrder?: string[];
  slots?: {
    [name: string]: KsSlotInfo;
  };
  slotOrder?: string[];
  /**
   * @todo evaluate & refine
   */
  // cssProps?: {
  //   [name: string]: {
  //     title: string;
  //     description: string;
  //   };
  // };
  hidePropsTable?: boolean;
  hideSlotsTable?: boolean;
  /**
   * Extra Docs are each shown in their own table.
   * Useful for non-traditional spec docs that are more template language specific.
   * Examples: events, methods, etc
   */
  extraDocs?: {
    id: string;
    title: string;
    description?: string;
    tableRows?: {
      [columnTitle: string]: string;
    }[];
  }[];
};

export interface KnapsackPatternTemplate {
  id: string;
  title?: string;
  /**
   * Relative file path to the template from the config file it is declared in
   */
  path: string;

  alias?: TemplateAlias;
  /**
   * Which template language? i.e. `twig`, `react` Replaces
   * KnapsackTemplateRenderer.test which ran a test on the file path (similar to
   * how WebPack loader `test` works)
   */
  templateLanguageId: RendererId;
  assetSetIds?: string[];
  spec?: KsTemplateSpec;
  /** Array of demo IDs, actual demo data is stored in `db.demos.demosById` */
  demoIds: Demo['id'][];
  /** Array of block IDs, actual block content is stored in db.blocks.blocksById */
  blockIds: BlockConfig['id'][];
}

export type KnapsackPattern = Page & {
  $schema?: string;
  statuses?: {
    [statusSetId: string]: string;
  };
  templates?: KnapsackPatternTemplate[];
  subPages?: {
    id: string;
    title: string;
    blockIds: BlockConfig['id'][];
  }[];
  designSrcComponentsById?: Record<
    string,
    {
      id: string;
      title: string;
      componentId: string;
      fileId: string;
    }
  >;
  /** Controls order of `templates` & `subPages` on Pattern Page */
  tabs?: PageTab[];

  aliases?: string[];
  /**
   * Prevents pattern from appearing in System Overview and Status Table blocks
   */
  hideFromOverviewBlocks?: boolean;
  /**
   * Prevents pattern from appearing as an option in Component Embed and
   * Component Demo blocks
   */
  hideFromComponentBlocks?: boolean;
  hideStatusSets?: boolean;
  thumbnail?: KnapsackImage;
};

export interface KnapsackPatternTemplateCode {
  html: string;
  data?: Record<string, any>;
  templateSrc: string;
  usage: string;
  language: string;
}

export type KnapsackPatternsConfig = {
  $schema?: string;
  statusSets: KsStatusSet[];
};

export interface TemplateRendererMeta {
  id: RendererId;
  title: string;
  version?: string;
  aliasTitle?: string;
  aliasDescription?: string;
  aliasUse: 'off' | 'optional' | 'required';
  aliasIsJsNamedExport?: boolean;
  syntaxHighlightingLanguage?: string;
  enableDataDemos: boolean;
  enableTemplateDemos: boolean;
  hasSlotsSupport: boolean;
  hasSlotOptionsSupport?: boolean;
  hasInferSpecSupport: boolean;
  hasTemplateSuggestionsSupport: boolean;
  prototypingTemplate?: {
    path: string;
    alias?: string;
    spec: Pick<KsTemplateSpec, 'props' | 'slots' | 'isInferred'>;
  };
}

export type PatternsState = {
  patterns: {
    [id: string]: KnapsackPattern;
  };
  renderers: Partial<
    Record<
      RendererId,
      {
        meta: TemplateRendererMeta;
      }
    >
  >;
} & KnapsackPatternsConfig;
