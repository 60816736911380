import type { NextJsFullPath } from '@/types';
import {
  Auth0Client,
  Auth0ClientOptions,
  AuthenticationError,
  type User,
  type IdToken,
  GenericError,
} from '@auth0/auth0-spa-js'; // https://github.com/auth0/auth0-spa-js
import { log } from './logger';

export type Auth0User = User;
export type Auth0IdToken = IdToken;
export { AuthenticationError };

type Auth0Scope =
  | 'openid'
  | 'profile'
  | 'email'
  | 'read:user'
  | 'write:user'
  | 'offline_access';
const scopes: Auth0Scope[] = [
  'openid',
  'profile',
  'email',
  'read:user',
  'write:user',
  'offline_access',
];

const config: Auth0ClientOptions = {
  domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  authorizationParams: {
    audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
    scope: scopes.join(' '),
  },
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  useRefreshTokensFallback: true, // https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/18
};
console.log(`Auth0 config(${process.env.NEXT_PUBLIC_AUTH_ENV}):`, config);
if (
  !config.domain ||
  !config.clientId ||
  !config.authorizationParams.audience
) {
  throw new Error('Auth0 config is not set correctly');
}

export const auth0 = new Auth0Client(config);

/**
 * Paths `/auth/*` used by Auth0
 */
export const authPaths = {
  /**
   * IdP initiated logins send user here, which then kicks off a SP initiated login
   * flow using `loginWithRedirect` to send them to `auth.knapsack.cloud/authorize` and then eventually back to `auth/callback`
   * Query params:
   * - `connection`: (required) The Auth0 connection to use
   * - `siteId`: The siteId to redirect to after login
   */
  startLogin: '/auth/startlogin',
  /**
   * Where Auth0 sends user back to with `code` and `state` params
   * Query params:
   * - `code`: The Auth0 code used to authenticate the user
   * - `state`: The Auth0 state used to authenticate the user
   * - `error`: The Auth0 error used to authenticate the user
   * - `error_description`: The Auth0 error description used to authenticate the user
   */
  callback: '/auth/callback',
} satisfies Record<string, NextJsFullPath>;

type PreAuthRedirectState = {
  /**
   * Pathname to where user was before, like `/site/foo/latest/pattern/button`
   */
  priorPathname: string;
  connection: string;
};

/**
 * Begin a SP initiated login flow using `loginWithRedirect`
 * Sends user to `auth.knapsack.cloud/authorize` and then eventually back to `auth/callback`
 */
export const redirectUserToAuth0AuthorizeEndpoint = ({
  connection,
  redirectToAfterCallback = window.location.pathname + window.location.search,
  onBeforeRedirect,
}: {
  connection: string;
  /**
   * Pathname to where user will be redirected to *after* `/auth/callback`
   * This is NOT the `redirect_uri` in the `authorizationParams` - it's where we go after that
   * @default `window.location.pathname` current pathname with query params
   */
  redirectToAfterCallback?: string;
  onBeforeRedirect?: (url: string) => void;
}) => {
  if (!redirectToAfterCallback.startsWith('/')) {
    throw new Error(
      `redirectToAfterCallback must start with "/" but did not: ${redirectToAfterCallback}`,
    );
  }
  const redirect_uri = new URL(
    authPaths.callback,
    window.location.origin,
  ).toString();

  return auth0.loginWithRedirect<PreAuthRedirectState>({
    authorizationParams: {
      connection,
      redirect_uri,
    },
    appState: {
      priorPathname: redirectToAfterCallback,
      connection,
    },
    openUrl(url) {
      onBeforeRedirect?.(url);
      return window.location.assign(url);
    },
  });
};

export function login({ connection }: { connection: string }) {
  if (!connection) {
    const error = new Error('No connection provided');
    log.error(`ui.auth.login: ${error.message}`, {
      error,
      evt: {
        name: 'ui.auth.login',
        redirectUrl: '',
        queryParams: {},
      },
    });
    return;
  }
  redirectUserToAuth0AuthorizeEndpoint({
    connection,
    redirectToAfterCallback: window.location.pathname + window.location.search,
    onBeforeRedirect: (redirectUrl) => {
      const u = new URL(redirectUrl);
      log.info('ui.auth.login', {
        evt: {
          name: 'ui.auth.login',
          redirectUrl,
          queryParams: Object.fromEntries(u.searchParams.entries()),
        },
      });
    },
  });
}

export const handleRedirectCallback = async () => {
  try {
    const {
      appState = {
        priorPathname: '/',
      },
    } = await auth0.handleRedirectCallback<PreAuthRedirectState>();
    return appState;
  } catch (e) {
    console.error(e);
    if (e instanceof AuthenticationError) {
      throw new Error(`"${e.error}": ${e.error_description}`);
    }
    if (e instanceof GenericError) {
      throw new Error(`"${e.error}": ${e.error_description}`);
    }
    throw e;
  }
};

export const logout = () =>
  auth0.logout({ logoutParams: { returnTo: window.location.origin } });
