import { StateNodeConfig, send, Action, forwardTo } from 'xstate';
import { PromiseResult } from '@/utils/type-utils';
import { assign } from '@xstate/immer';
import {
  trackEvent,
  updateAnalyticsSite,
  removeAnalyticsSite,
} from '@/utils/analytics';
import { featureFlags } from '@/utils/feature-flags';
import { appClientMinimumVersion } from '@/utils/constants';
import { swapInstanceIdInUrl } from '@/domains/branches/utils';
import { resetPlugins, loadPlugins } from '@/domains/plugins';
import semverLessThan from 'semver/functions/lt';
import {
  removeActiveEnvUrl,
  setActiveEnvUrl,
} from '@/core/env-and-content-src/env-url-storage';
import { getEnvDetails } from '@/core/env-and-content-src/utils';
import { Env } from '@/core/env-and-content-src/env-and-content-src.types';
import { knapsackGlobal } from '@/global';
import { createToast } from '@knapsack/toby';
import * as appGuards from '../app.xstate-guards';
import type { AppClientDataEvents } from '../../app-client-data';
import {
  AppEvents,
  AppContext,
  AppStateSchema,
  createInvokablePromise,
  getPlansFlags,
  APP_SUB_MACHINE_IDS,
} from '../app.xstate-utils';
import { LocalDevCtx, localDevMachine } from './site.localDev.xstate';
import { UiEvents } from '../../ui/types';

const envLoadActions: Action<AppContext, AppEvents>[] = [
  send((): AppEvents => ({ type: 'site.reloadPlugins' })),
  send(
    (ctx): AppClientDataEvents => ({
      type: 'env.switch',
      env: ctx.site.env,
    }),
    { to: APP_SUB_MACHINE_IDS.appClientData },
  ),
  (ctx) => {
    if (ctx.site.env.type === 'production') {
      removeActiveEnvUrl({ siteId: ctx.site.meta.siteId });
    } else {
      setActiveEnvUrl({
        siteId: ctx.site.meta.siteId,
        appClientUrl: ctx.site.env.url,
      });
    }
  },
];

export const siteStateConfig: StateNodeConfig<
  AppContext,
  AppStateSchema['states']['site'],
  AppEvents
> = {
  id: 'site',
  strict: true,
  initial: 'unloaded',
  on: {
    'site.loadInstance': {
      target: '.loaded',
      actions: [
        send(
          (): AppClientDataEvents => ({
            // only needed for switching instances, will be no-op if not switching
            type: 'appClientData.prepForInstanceSwitch',
          }),
          { to: APP_SUB_MACHINE_IDS.appClientData },
        ),
        send(
          (
            _ctx,
            { data: { site, appClientData, tokenData, tokenStyles } },
          ): AppClientDataEvents => {
            return {
              type: 'knapsack/SET_APP_CLIENT_DATA',
              payload: appClientData,
              site,
              tokenData,
              tokenStyles,
            };
          },
          { to: APP_SUB_MACHINE_IDS.appClientData },
        ),
        assign((ctx, { data }) => {
          ctx.site = data.site;
          ctx.featureFlags = {
            ...ctx.featureFlags,
            ...getPlansFlags(data.site.meta.plan),
          };
        }),
      ],
    },
    'site.switchInstance': {
      actions: [
        assign((ctx, { instance }) => {
          if (ctx.site.contentSrc.type !== 'cloud-authoring') {
            throw new Error(
              'Cannot switch instance when contentSrc is not cloud-authoring',
            );
          }
          const { newInstanceUrl } = swapInstanceIdInUrl({
            newInstanceId:
              instance.type === 'latest' ? 'latest' : instance.instanceId,
          });
          // the below code will use router
          knapsackGlobal.goToUrl(newInstanceUrl);
          trackEvent({
            type: 'Branch Switched',
            metadata: {
              fromLatest: ctx.site.contentSrc.instance.type === 'latest',
            },
          });
        }),
        send((): UiEvents => ({ type: 'modal.triggerClose' }), {
          to: APP_SUB_MACHINE_IDS.ui,
        }),
      ],
    },
  },
  states: {
    unloaded: {},
    loaded: {
      type: 'parallel',
      entry: [
        assign((ctx) => {
          if (featureFlags.forceSiteToPrivate) {
            ctx.site.meta.isPrivate = true;
          }
        }),
      ],
      exit: [
        assign((ctx) => {
          ctx.site = null;
        }),
      ],
      on: {
        'site.statusChanged': {
          actions: assign((ctx, { status }) => {
            ctx.site.meta.status = status;
          }),
        },
        'site.instanceStatusChanged': {
          actions: [
            assign((ctx, { instanceStatus }) => {
              if (
                ctx.site?.contentSrc?.type === 'cloud-authoring' &&
                ctx.site.contentSrc.instance.type === 'branch'
              ) {
                ctx.site.contentSrc.instance.instanceStatus = instanceStatus;
              }
            }),
            forwardTo(APP_SUB_MACHINE_IDS.appClientData),
          ],
        },
      },
      states: {
        env: {
          initial: 'unknown',
          on: {
            'site.switchEnvUrl': '.switching',
          },
          states: {
            unknown: {
              always: [
                {
                  cond: (ctx) => ctx.site?.env?.type === 'production',
                  target: 'production',
                },
                {
                  cond: (ctx) => ctx.site?.env?.type === 'development',
                  target: 'development',
                },
                {
                  cond: (ctx) => ctx.site?.env?.type === 'preview',
                  target: 'preview',
                },
              ],
            },
            switching: {
              invoke: createInvokablePromise<Env>({
                id: 'switch-env',
                src: async (ctx, event) => {
                  if (event.type !== 'site.switchEnvUrl') {
                    throw new Error(
                      `Event type "${event.type}" is not supported`,
                    );
                  }
                  const env = await getEnvDetails({
                    envUrl: event.envUrl,
                    prodEnvUrl: ctx.site.meta.prodEnvUrl,
                  });
                  if (env instanceof Error) throw env;
                  if (
                    env.appClientMeta.knapsackCloudSiteId !==
                    ctx.site.meta.siteId
                  ) {
                    throw new Error(
                      `Env ${event.envUrl} does not match site ${ctx.site.meta.siteId}`,
                    );
                  }
                  return env;
                },
                onDone: {
                  target: 'unknown', // will handle transition target
                  actions: assign((ctx, { data: env }) => {
                    ctx.site.env = env;
                  }),
                },
                onErrorTarget: 'unknown', // will restore to previous env
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, { data: error }) {
                      createToast({
                        type: 'error',
                        title: 'Error Switching Env',
                        message: error.message,
                      });
                    },
                  },
                ],
              }),
            },
            production: {
              entry: envLoadActions,
            },
            preview: {
              entry: envLoadActions,
            },
            development: {
              entry: envLoadActions,
              invoke: {
                id: localDevMachine.id,
                src: localDevMachine,
                data: ({ site }): LocalDevCtx => ({ site }),
                onDone: {
                  actions: send(
                    (ctx): AppEvents => ({
                      type: 'site.switchEnvUrl',
                      envUrl: ctx.site.meta.prodEnvUrl,
                    }),
                  ),
                },
              },
            },
          },
        },
        appClient: {
          initial: 'viewable',
          states: {
            viewable: {
              initial: 'uneditable',
              // On entry into viewable, fire off event that can be forwarded
              // (from app.xstate) to other machines (like ui.xstate) so they
              // can know the viewable state
              entry: [
                ({
                  site: {
                    meta: { orgId, siteId },
                  },
                }) => {
                  updateAnalyticsSite({
                    siteId,
                    orgId,
                  });
                },
                {
                  type: 'sendUserAppClientUpdateMessage',
                  exec(ctx: AppContext, event: AppEvents) {
                    const appClientVersion =
                      ctx.site?.env?.appClientMeta?.ksVersions?.app;
                    if (appClientVersion) {
                      const isOlderAppClient = semverLessThan(
                        appClientVersion,
                        appClientMinimumVersion.desired,
                      );
                      if (isOlderAppClient) {
                        createToast({
                          type: 'warning',
                          message: `Please update your @knapsack NPM packages to "${appClientMinimumVersion.desired}" to use latest features. You're currently on "${appClientVersion}"`,
                        });
                      } else {
                        console.debug(
                          `App Client Version: ${appClientVersion}, UI's version: ${appClientMinimumVersion.desired}`,
                        );
                      }
                    }
                  },
                },
              ],
              exit: () => removeAnalyticsSite(),
              states: {
                uneditable: {
                  always: [
                    {
                      target: 'editable',
                      cond: appGuards.isEditable,
                    },
                  ],
                },
                error: {},
                preview: {
                  always: [
                    {
                      cond: function isUneditable(...args) {
                        return !appGuards.isEditable(...args);
                      },
                      target: 'uneditable',
                    },
                  ],
                  on: {
                    'site.previewOff': 'uneditable', // after the `cond` passes it'll go back to 'editable'
                  },
                },
                editable: {
                  always: [
                    {
                      cond: function isUneditable(...args) {
                        return !appGuards.isEditable(...args);
                      },
                      target: 'uneditable',
                    },
                  ],
                  on: {
                    'site.previewOn': 'preview',
                  },
                },
              },
            },
          },
        },
        plugins: {
          initial: 'loading',
          on: {
            'site.reloadPlugins': '.loading',
          },
          states: {
            loading: {
              invoke: createInvokablePromise<PromiseResult<typeof loadPlugins>>(
                {
                  id: 'loadPlugins',
                  src: async (ctx) => {
                    return loadPlugins({
                      urlBase: ctx.site?.env?.url,
                      pluginMetas: ctx.site?.env?.appClientMeta.plugins,
                    });
                  },
                  onErrorTarget: 'error',
                  onDone: [
                    {
                      target: 'loaded',
                      cond: (ctx, { data }) =>
                        data.filter(({ ok }) => !ok).length === 0,
                      actions: assign((ctx, event) => {
                        ctx.pluginsLoadResults = event.data;
                      }),
                    },
                    {
                      target: 'partiallyLoaded',
                      cond: (ctx, { data }) =>
                        data.filter(({ ok }) => ok).length > 0,
                      actions: assign((ctx, event) => {
                        ctx.pluginsLoadResults = event.data;
                      }),
                    },
                    {
                      target: 'error',
                    },
                  ],
                },
              ),
            },
            loaded: {
              exit: [
                {
                  type: 'unload plugins',
                  exec: () => resetPlugins(),
                },
              ],
            },
            partiallyLoaded: {
              exit: [
                {
                  type: 'unload plugins',
                  exec: () => resetPlugins(),
                },
              ],
            },
            error: {},
          },
        },
      },
    },
  },
};
