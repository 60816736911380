import { getKsUrls } from '@knapsack/core';
import { featureFlags } from '@/utils/feature-flags';

const { NEXT_PUBLIC_KS_DB_URL_ORIGIN, NEXT_PUBLIC_KS_API_URL_ORIGIN } =
  process.env;

export const ksUrls = getKsUrls({
  dbUrl: NEXT_PUBLIC_KS_DB_URL_ORIGIN,
  apiUrl: featureFlags.appApiGqlEndpoint || NEXT_PUBLIC_KS_API_URL_ORIGIN,
});
