'use client';

import { useUiStateMatches, sendUiEvent, useUiCtxSelector } from '@/core';
import {
  Flex,
  Skeleton,
  Modal as TobyModal,
  TobyModalProps,
} from '@knapsack/toby';
import { Except } from '@knapsack/utils';
import { SuspenseAndErrorCatcher } from '@/utils/error-catcher';

export type ModalProps = Except<TobyModalProps, 'onToggle' | 'opened'> & {
  /**
   * onToggle is required in the TobyModalProps, but we override it here
   * to make it optional to allow additional events when needed
   */
  onToggle?: () => void;
};

const Modal = () => {
  const isModalOpened = useUiStateMatches('modal.opened');
  const modal = useUiCtxSelector((ui) => ui.modal);

  return (
    <TobyModal
      body={
        <SuspenseAndErrorCatcher
          fallback={
            <Flex
              direction="column"
              gap="small"
              align="stretch"
              justify="center"
            >
              <Skeleton animated round size="xsmall" />
              <Skeleton animated round size="xsmall" />
              <Skeleton animated round size="xsmall" />
            </Flex>
          }
        >
          {modal?.body}
        </SuspenseAndErrorCatcher>
      }
      bodyClassName={modal?.bodyClassName}
      footer={
        <SuspenseAndErrorCatcher
          fallback={
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          }
        >
          {modal?.footer}
        </SuspenseAndErrorCatcher>
      }
      onToggle={() => {
        modal?.onToggle?.();
        sendUiEvent('modal.triggerClose');
      }}
      opened={isModalOpened}
      preventClose={modal?.preventClose}
      preventFocusTrap={modal?.preventFocusTrap}
      size={modal?.size}
      subtitle={modal?.subtitle}
      tabs={modal?.tabs}
      testId={modal?.testId}
      title={modal?.title}
      hideCloseButton={modal?.hideCloseButton}
      instantOpen={modal?.instantOpen}
      preventEscKeyClose={modal?.preventEscKeyClose}
      tabBarStyles={modal?.tabBarStyles}
    />
  );
};

export default Modal;
