/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/open#Window_features
 */
export const openPopup = ({
  url,
  name = 'knapsack:popup',
  width = 400,
  height = 600,
}: {
  url: string;
  name?: string;
  width?: number;
  height?: number;
}) => {
  const left = window.screenX + (window.innerWidth - width) / 2;
  const top = window.screenY + (window.innerHeight - height) / 2;

  // first try popup, then fallback to new tab
  return (
    window.open(
      url,
      name,
      `left=${left},top=${top},width=${width},height=${height},resizable,scrollbars=yes`,
    ) || window.open(url, name, '_blank')
  );
};
