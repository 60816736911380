/* eslint-disable prefer-arrow-callback */
import { GenericResponse } from '@knapsack/types';
import { slugify } from '@knapsack/utils';
import { fbUploadTask } from '@/firebase';
import axios, { AxiosProgressEvent } from 'axios';
import { captureException } from '@/utils/sentry';
import { createUniqueFilename } from '@/utils/file-upload-utils';
import { knapsackGlobal } from '../global';
import { appApiGql } from './app-api-client';
import { imgixBaseUrl, awsS3BaseUrl } from './image-resize';

export type FileResponse = GenericResponse<{
  publicPath: string;
  mimetype: string;
  /**
   * File size in bytes
   */
  size: number;
  originalName: string;
  filename: string;
}>;

export function createMasterImageUrl({
  pathname,
  enableS3FileUploads,
}: {
  pathname: string;
  enableS3FileUploads?: boolean;
}): string {
  return new URL(
    pathname,
    enableS3FileUploads ? awsS3BaseUrl : imgixBaseUrl,
  ).toString();
}

export function uploadImage({
  file,
  isUserSpecific = false,
  onProgress,
}: {
  file: File;
  /**
   * If `true` then will not be tied to a site's images (which could get deleted when site is deleted). Users can access multiple sites.
   */
  isUserSpecific?: boolean;
  onProgress?: (info: { percent: number; done: number; total: number }) => void;
}): Promise<FileResponse> {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error(`Must pass in a file to upload!`));
      return;
    }
    const {
      site,
      user,
      featureFlags: { enableS3FileUploads },
    } = knapsackGlobal.appService.getSnapshot().context;
    const { siteId } = site?.meta ?? {};
    const { userId } = user ?? {};

    function handleError(error: Error) {
      captureException(error);
      resolve({
        ok: false,
        message: error.message,
      });
    }

    function handleChange({
      bytesTransferred,
      totalBytes,
    }: {
      bytesTransferred: number;
      totalBytes: number;
    }) {
      const percent = (bytesTransferred / totalBytes) * 100;
      onProgress?.({
        percent,
        done: bytesTransferred,
        total: totalBytes,
      });
    }

    function handleSuccess({
      pathname,
      filename,
    }: {
      pathname: string;
      filename: string;
    }) {
      const url = createMasterImageUrl({ pathname, enableS3FileUploads });
      resolve({
        ok: true,
        data: {
          publicPath: url,
          filename,
          mimetype: file.type,
          originalName: file.name,
          size: file.size,
        },
      });
    }

    if (enableS3FileUploads) {
      // Upload to AWS S3
      const filename = slugify({ string: file.name });

      // Creates an authenticated PUT url to the file-upload S3 endpoint though cloudfront
      appApiGql
        .preSignS3URL({
          presignRequest: {
            fileName: filename,
            fileType: isUserSpecific ? 'USER' : 'SITE',
            mimeType: file.type,
            userId,
            siteId,
          },
        })
        .then((response) => {
          const url = new URL(response.preSignS3URL);
          const { pathname } = url;
          // Upload file to S3
          axios({
            method: 'put',
            url: response.preSignS3URL,
            data: file,
            onUploadProgress(progressEvent: AxiosProgressEvent) {
              handleChange({
                bytesTransferred: progressEvent.loaded,
                totalBytes: progressEvent.total,
              });
            },
          })
            .then((s3Response) => {
              handleSuccess({ pathname, filename });
            })
            .catch((error: Error) => {
              handleError(error);
            });
        })
        .catch((error: Error) => {
          handleError(error);
        });
    } else {
      const filename = createUniqueFilename({ filename: file.name });
      const subDir = isUserSpecific
        ? `user/${userId || 'anonymous'}`
        : `site/${siteId || 'unknown'}`;
      const pathname = `${subDir}/${filename}`;
      const metadata = {
        customMetadata: {
          siteId,
          userId,
        },
      };
      fbUploadTask({
        url: `images/${pathname}`,
        data: file,
        meta: metadata,
      }).on('state_changed', handleChange, handleError, () => {
        handleSuccess({ pathname, filename });
      });
    }
  });
}
