export enum GitProviderType {
  GitHub = 'GitHub',
  GitLab = 'GitLab',
  GitLabRest = 'GitLabRest',
  Bitbucket = 'Bitbucket',
  Azure = 'Azure',
}

export type FileAddition = {
  /** base64 */
  contents: string;
  path: string;

  /** Action to perform: create, delete, move, update, or chmod. */
  action?: RestActions;
  /** Content of the file. */
  content?: string;
  /** Full path to the file. */
  filePath?: string;
  /** Encoding of the file. Default is text. */
  encoding?: TextEncoding;
  /** Last known file commit ID. */
  lastCommitId?: string;
  /** Original full path to the file being moved. */
  previousPath?: string;
};

export type FileDeletion = {
  path: string;
};

export enum MergeableState {
  Conflicting = 'CONFLICTING',
  Mergeable = 'MERGEABLE',
  Unknown = 'UNKNOWN',
}

export enum RestActions {
  Create = 'CREATE',
  Move = 'MOVE',
  Update = 'UPDATE',
  Chmod = 'CHMOD',
}

export enum TextEncoding {
  Base64 = 'BASE64',
  Text = 'TEXT',
}

export type FileChanges = {
  /** File to add or change. */
  additions?: FileAddition[];

  /** Files to delete. */
  deletions?: FileDeletion[];

  /** Last known file commit ID. */
  lastCommitId?: string;
};

export type CommitAction = Record<string, unknown>;

export interface RepoLabel {
  name: string;
  /** ID optional because bitbucket does not provide id. We might be able to generate one if its required, eg. reponame-labelname */
  id?: string;
}

export interface RepoInfo {
  id: string;
  url: string;
  name: string;
  ownerName?: string;
  defaultBranch?: any;
}

// 'BranchInfo' name is currently taken by GitLab generated queries. Thus 'BranchInfoResult'
export interface BranchInfoResult {
  sha?: string; // If branch info is not found, this can be undefined
  shortSha?: string;
  associatedPullRequests?: Pr[];
  additions?: number;
  commitUrl?: string;
  deletions?: number;
  id?: string; // In GitLab, this is the branch name. Modelled this way to make deleteBranch work.
  default?: boolean;
}

export interface PrInfo {
  url: string;
  number: number;
  id: string;
}

export interface Pr {
  id: string;
  title: string;
  createdAt: string;
  headRef: {
    id?: string;
    name?: string;
    target: BranchInfoResult;
  };
  mergeable: MergeableState;
  merged: boolean;
  number: number;
  body: string;
  isDraft: boolean;
  url: string;
  author: {
    login: string;
    avatarUrl?: string;
  };
  diffHeadSha?: string;
  targetBranch?: string;
  version?: string; // needed for bitbucket merge
}

export interface GitProvider {
  type: GitProviderType;
  createBranch: (opt: { branch: string }) => Promise<BranchInfoResult>;
  commitOnBranch: (opt: {
    branch: string;
    message: string;
    fileChanges: FileChanges;
  }) => Promise<Record<string, unknown> | void>;
  getRepoLabels: () => Promise<RepoLabel[]>;
  getRepoInfo: () => Promise<RepoInfo>;
  getBranchInfo: ({ branch }: { branch: string }) => Promise<BranchInfoResult>;
  getPrs: () => Promise<Pr[]>;
  getPr: ({ prNumber }: { prNumber: number }) => Promise<Pr>;
  getTree?: (opt: { branch: string }) => Promise<{
    treeItems: {
      path: string;
      type: 'blob';
      /** SHA-1, use https://www.npmjs.com/package/git-hash-object */
      sha: string;
      url?: string;
    }[];
  }>;
  mergePr: ({ prNumber }: { prNumber: number }) => Promise<boolean>;
  createPr: ({
    siteId,
    branch,
    title,
    body,
    labelIds,
  }: {
    siteId: string;
    branch: string;
    title: string;
    body?: string;
    labelIds?: string[];
  }) => Promise<PrInfo>;
  deleteBranch: ({ id, branch }: { id: string; branch: string }) => Promise<{
    clientMutationId?: string;
  }>;
  getFileInfoOnBranchByPath: (opt: {
    filePath: string;
    branch: string;
  }) => Promise<{ exists: boolean; filePath: string }>;
}
