// import { JSONSchema7 } from 'json-schema'; @todo consider using instead; though I'm not seeing it make it's way to the compiled JSON schema themselves via `convert-types-to-json-schmea.js`

type PropBase<Type = any> = {
  title?: string;
  description?: string;
  default?: Type;
};

export enum PropTypeNames {
  'string' = 'string',
  'boolean' = 'boolean',
  'number' = 'number',
  'object' = 'object',
  'options' = 'options',
  'function' = 'function',
  'customTypeScript' = 'customTypeScript',
  'array' = 'array',
  'arrayOfObjects' = 'arrayOfObjects',
  'arrayOfStrings' = 'arrayOfStrings',
  'arrayOfNumbers' = 'arrayOfNumbers',
  'unknown' = 'unknown',
}
export type PropTypeNamesList = keyof typeof PropTypeNames;

export type StringProp = {
  type: 'string';
} & PropBase<string>;

export type BooleanProp = {
  type: 'boolean';
} & PropBase<boolean>;

export type NumberProp = {
  type: 'number';
} & PropBase<number>;

export type OptionsProp = {
  type: 'string' | 'number';
  enum: Array<string | number>;
  enumNames?: string[];
} & PropBase<string | number>;

export type CustomTypeScriptProp = {
  /**
   * TypeScript type
   * @example (name: string) => void
   */
  tsType: string;
  type?: never;
  default?: string;
} & PropBase<string>;

export type FunctionProp = {
  typeof: 'function';
  /**
   * TypeScript type
   * @example (name: string) => void
   */
  tsType: string;
  default?: string;
  type?: 'string';
} & PropBase<string>;

export type ObjectProp = {
  type: 'object';
  additionalProperties?: boolean;
  required?: string[];
  properties: {
    [prop: string]: PropertyTypes;
  };
} & PropBase;

export type ArrayProp = {
  type: 'array';
  items: PropertyTypes;
} & PropBase<unknown[]>;

export type ArrayOfStringsProp = {
  type: 'array';
  uniqueItems?: boolean;
  items: StringProp;
} & PropBase<unknown[]>;

export type ArrayOfNumbersProp = {
  type: 'array';
  uniqueItems?: boolean;
  items: NumberProp;
} & PropBase<number[]>;

export type ArrayOfObjectsProp = {
  type: 'array';
  items: ObjectProp;
} & PropBase<Array<Record<string, unknown>>>;

export type PropertyTypes =
  | StringProp
  | BooleanProp
  | NumberProp
  | OptionsProp
  | FunctionProp
  | CustomTypeScriptProp
  | ObjectProp
  | ArrayOfObjectsProp
  | ArrayOfStringsProp
  | ArrayOfNumbersProp
  | ArrayProp;

export const isStringProp = (prop: PropertyTypes): prop is StringProp =>
  'type' in prop && prop.type === 'string' && !('enum' in prop);
export const isNumberProp = (prop: PropertyTypes): prop is NumberProp =>
  'type' in prop && prop.type === 'number';
export const isBooleanProp = (prop: PropertyTypes): prop is BooleanProp =>
  'type' in prop && prop.type === 'boolean';
export const isOptionsProp = (prop: PropertyTypes): prop is OptionsProp =>
  'enum' in prop;
export const isFunctionProp = (prop: PropertyTypes): prop is FunctionProp =>
  'typeof' in prop && prop?.typeof === 'function';
export const isCustomTypeScriptProp = (
  prop: PropertyTypes,
): prop is CustomTypeScriptProp => !('typeof' in prop) && 'tsType' in prop;
export const isArrayOfObjectsProp = (
  prop: PropertyTypes,
): prop is ArrayOfObjectsProp =>
  'type' in prop &&
  prop.type === 'array' &&
  'items' in prop &&
  prop.items.type === 'object';
export const isObjectProp = (prop: PropertyTypes): prop is ObjectProp =>
  'type' in prop &&
  prop.type === 'object' &&
  'properties' in prop &&
  Object.keys(prop.properties).length > 0;
export const isArrayOfStringsProp = (
  prop: PropertyTypes,
): prop is ArrayOfStringsProp =>
  'type' in prop &&
  prop.type === 'array' &&
  'items' in prop &&
  prop.items.type === 'string';
export const isArrayOfNumbersProp = (
  prop: PropertyTypes,
): prop is ArrayOfNumbersProp =>
  'type' in prop &&
  prop.type === 'array' &&
  'items' in prop &&
  prop.items.type === 'number';

export interface PropTypeDataBase {
  type: any;
  id: string;
  isRequired?: boolean;
  data: any;
}

export interface StringPropTypeData extends PropTypeDataBase {
  type: typeof PropTypeNames.string;
  data: StringProp;
}

export interface BooleanPropTypeData extends PropTypeDataBase {
  type: typeof PropTypeNames.boolean;
  data: BooleanProp;
}

export interface NumberPropTypeData extends PropTypeDataBase {
  type: typeof PropTypeNames.number;
  data: NumberProp;
}

export interface OptionsPropTypeData extends PropTypeDataBase {
  type: typeof PropTypeNames.options;
  data: OptionsProp;
}

export interface FunctionPropTypeData extends PropTypeDataBase {
  type: typeof PropTypeNames.function;
  data: FunctionProp;
}

export interface CustomTypeScriptPropTypeData extends PropTypeDataBase {
  type: typeof PropTypeNames.customTypeScript;
  data: CustomTypeScriptProp;
}

export interface ObjectPropTypeData extends PropTypeDataBase {
  type: typeof PropTypeNames.object;
  data: ObjectProp;
}

export interface ArrayOfObjectsPropTypeData extends PropTypeDataBase {
  type: typeof PropTypeNames.arrayOfObjects;
  data: ArrayOfObjectsProp;
}

export interface ArrayOfStringsPropTypeData extends PropTypeDataBase {
  type: typeof PropTypeNames.arrayOfStrings;
  data: ArrayOfStringsProp;
}

export interface ArrayOfNumbersPropTypeData extends PropTypeDataBase {
  type: typeof PropTypeNames.arrayOfNumbers;
  data: ArrayOfNumbersProp;
}

export interface ArrayPropTypeData extends PropTypeDataBase {
  type: typeof PropTypeNames.array;
  data: ArrayProp;
}

export type PropTypeData =
  | StringPropTypeData
  | BooleanPropTypeData
  | NumberPropTypeData
  | OptionsPropTypeData
  | FunctionPropTypeData
  | CustomTypeScriptPropTypeData
  | ObjectPropTypeData
  | ArrayOfObjectsPropTypeData
  | ArrayOfStringsPropTypeData
  | ArrayOfNumbersPropTypeData;

export type JsonSchemaObject<PropNames extends string = string> = {
  $schema?: string;
  title?: string;
  description?: string;
  type: 'object';
  required?: PropNames[];
  properties: Record<PropNames, PropertyTypes>;
  examples?: Record<string, unknown>[];
};
