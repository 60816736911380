import type {
  EndpointClientFigmaOAuthCallback,
  EndpointClientFigmaOAuthCheck,
} from '@knapsack/api-server/types';
import { useQuery } from '@tanstack/react-query';
import { ksUrls } from '@/services/ks-urls';
import { getHttpHeaders } from './http-headers-utils';

async function getHeaders() {
  const headers = await getHttpHeaders();
  return {
    'Content-Type': 'application/json',
    ...headers,
  };
}

export const exchangeFigmaOAuthCodeForToken: EndpointClientFigmaOAuthCallback =
  async (body) => {
    const apiUrl = new URL(
      '/design-srcs/figma-oauth',
      ksUrls.apiOrigin,
    ).toString();
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: await getHeaders(),
      body: JSON.stringify(body),
    });
    return response.json();
  };

export const checkIfFigmaTokenExists: EndpointClientFigmaOAuthCheck =
  async () => {
    const apiUrl = new URL(
      '/design-srcs/figma-oauth',
      ksUrls.apiOrigin,
    ).toString();
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: await getHeaders(),
    });

    if (response.status === 403) {
      throw new Error('Figma token is invalid or expired');
    }

    return response.json();
  };

export function useCheckIfFigmaTokenExists() {
  const queryInfo = useQuery({
    queryKey: ['checkIfFigmaTokenExists'],
    queryFn: () => checkIfFigmaTokenExists({}),
    retry: (failureCount, error) => {
      // Do not retry if error is 403
      if (error.message.includes('Figma token is invalid or expired')) {
        return false;
      }
      return failureCount < 3;
    },
  });

  return {
    ...queryInfo,
    data: queryInfo.data,
  };
}
