export const ksHttpHeaders = {
  siteId: 'X-KS-Site-ID',
  instanceId: 'X-KS-Instance-Id',
  appClientVersion: 'X-KS-App-Client-Version',
  requestId: 'X-KS-Request-Id',
  /**
   * Value is {import('@knapsack/core').AuthEnvId}
   */
  authEnv: 'X-KS-Auth-Env',
  auth0TenantId: 'X-KS-Auth0-Tenant-Id',
} as const;
