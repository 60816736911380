type AuthEnv = {
  /**
   * The KS environment ID
   * @example 'prod'
   */
  id: string;
  /**
   * The Auth0 tenant ID
   * @example 'knapsack-auth'
   */
  tenantId: string;
  /**
   * The Auth0 domain
   * @example 'auth.knapsack.cloud'
   */
  domain: string;
};

export const authEnvs = {
  prod: {
    id: 'prod',
    tenantId: 'knapsack-auth',
    domain: 'auth.knapsack.cloud',
  },
  dev: {
    id: 'dev',
    tenantId: 'knapsack-auth-dev',
    domain: 'auth-dev.knapsack.cloud',
  },
  test1: {
    id: 'test1',
    tenantId: 'ks-auth-test1',
    domain: 'auth-test1.knapsack.cloud',
  },
  test2: {
    id: 'test2',
    tenantId: 'ks-auth-test2',
    domain: 'auth-test2.knapsack.cloud',
  },
} as const satisfies Record<string, AuthEnv>;

export type AuthEnvId = keyof typeof authEnvs;
export function isAuthEnvId(id: string): id is AuthEnvId {
  return id in authEnvs;
}
