import { Draft } from 'immer';
import type {
  BlockConfig,
  BlockCollectionLocation,
  Demo,
  Except,
  KsAppClientDataNoMeta,
  DataDemo,
  TemplateDemo,
} from '@knapsack/types';
import { deepMerge, makeShortId, now } from '@knapsack/utils';
import { getBlockIds } from '@/domains/blocks/utils/block-collection-utils';
import { migrateUiConfig } from '@/utils/ui-config';
import { trackEvent } from '@/utils/analytics';
import arrayMove from 'array-move';
import {
  SET_APP_CLIENT_DATA,
  SetAppClientData,
  RESET_APP_CLIENT_DATA,
  ResetAppClientData,
} from './shared.xstate';
import {
  duplicateDataDemo,
  mergeDeepInImmer,
  removeBlockFromContainer,
} from './utils/utils.xstate';

export type DbState = KsAppClientDataNoMeta['db'];

export const dbInitialState: DbState = {
  blocks: {
    byId: {},
    settings: {
      tokens: {
        rootFontSize: '16px',
      },
    },
  },
  demos: {
    byId: {},
    settings: {},
  },
  settings: {
    title: '',
    logoUrl: '',
  },
};

export type DbActions =
  | SetAppClientData
  | ResetAppClientData
  | {
      type: 'blocks.update.data';
      blockId: string;
      data: BlockConfig['data'];
    }
  | {
      type: 'blocks.update.header.content';
      blockId: string;
      content: Except<BlockConfig['header'], 'showHeader'>;
    }
  | {
      type: 'blocks.update.header.toggleVisibility';
      blockId: string;
    }
  | {
      type: 'blocks.update.size';
      blockId: string;
      size: BlockConfig['size'];
    }
  | {
      type: 'blocks.update.spacing';
      blockId: string;
      spacing: BlockConfig['spacing'];
    }
  | {
      type: 'blocks.delete';
      blockId: string;
      blockCollectionLocation: BlockCollectionLocation;
    }
  | {
      type: 'blocks.reorder';
      blockCollectionLocation: BlockCollectionLocation;
      fromIndex: number;
      toIndex: number;
    }
  | {
      type: 'blocks.replace';
      blockId: string;
      initialData: BlockConfig['data'];
      newBlockType: BlockConfig['blockType'];
    }
  | {
      type: 'blocks.duplicate';
      blockId: string;
      blockCollectionLocation: BlockCollectionLocation;
    }
  | {
      type: 'blocks.add';
      blockCollectionLocation: BlockCollectionLocation;
      blockType: BlockConfig['blockType'];
      index: number;
      initialData: BlockConfig['data'];
      isHeaderVisible?: BlockConfig['header']['showHeader'];
      size?: BlockConfig['size'];
    }
  | {
      type: 'blocks.moveToCollection';
      blockId: string;
      from: BlockCollectionLocation;
      to: BlockCollectionLocation;
    }
  | {
      type: 'demos.add';
      addToTemplate?: boolean;
      info:
        | {
            type: 'data';
            id?: string;
            title?: string;
            description?: string;
            patternId: string;
            templateId: string;
            initialData?: DataDemo['data'];
          }
        | {
            type: 'template';
            id?: string;
            title?: string;
            description?: string;
            patternId: string;
            templateId: string;
            templateInfo: TemplateDemo['templateInfo'];
          };
      userId: string;
    }
  | {
      type: 'demos.duplicate';
      /** The demo to duplicate */
      demoId: string;
      newDemoId: string;
      userId: string;
    }
  | {
      type: 'demos.update';
      demo: Demo;
      userId: string;
    }
  | {
      type: 'settings.update';
      settings: Partial<DbState['settings']>;
    }
  | {
      type: 'settings.pages.update';
      settings: Partial<DbState['settings']['pages']>;
    }
  | {
      type: 'settings.patterns.update';
      settings: Partial<DbState['settings']['patterns']>;
    }
  | {
      type: 'settings.theme.update';
      settings: Partial<DbState['settings']['theme']>;
    }
  | {
      type: 'tokenSettings.theming.setEnabledRendererIds';
      enabledRendererIds: string[];
    }
  | {
      type: 'blocks.settings.update';
      settings: Partial<DbState['blocks']['settings']>;
    };

export default function reducer(
  data: Draft<KsAppClientDataNoMeta>,
  action: DbActions,
): DbState {
  /**
   * Used for getting the `blockIds` from a `BlockCollectionLocation`
   * The reason we return `{ blockIds: string[] }` instead of `string[]` is b/c
   * with Immer we can do `result.blocksIds = newBlockIds` but we can't do `result = newBlockIds`
   */
  function getBlockIdsParent(blockCollectionLocation: BlockCollectionLocation) {
    return getBlockIds({
      blockCollectionLocation,
      appClientData: data,
    });
  }

  switch (action.type) {
    case SET_APP_CLIENT_DATA:
      // we do this b/c we may add top level keys that have a nested `.byId` key
      // a shallow merge would not handle that properly

      // NOTE: we can do migrations/scaffolding here if needed

      data.db = deepMerge(dbInitialState, action.payload.db ?? {});
      data.db.settings.theme = migrateUiConfig(
        action.payload.db.settings.theme,
      );
      return;
    case RESET_APP_CLIENT_DATA:
      data.db = dbInitialState;
      return;
    case 'blocks.settings.update':
      mergeDeepInImmer({
        target: data.db.blocks.settings,
        source: action.settings,
        sourceIsPartial: true,
      });
      return;
    case 'blocks.update.data': {
      const block = data.db.blocks.byId[action.blockId];
      if (!block) return;
      /**
       * Some block types start with no data, but could have data added later.
       * We need to make sure we have a data object to merge into
       */
      if (!block.data) {
        block.data = {};
      }
      Object.assign(block.data, action.data);
      return;
    }

    case 'blocks.update.header.content': {
      const block = data.db.blocks.byId[action.blockId];
      if (!block) return;
      if (!block.header) {
        block.header = {};
      }
      const { showHeader } = block.header;
      // `showHeader` is excluded from action.content
      mergeDeepInImmer({
        target: block.header,
        source: action.content,
        sourceIsPartial: false,
      });
      block.header.showHeader = showHeader;
      return;
    }
    case 'blocks.update.header.toggleVisibility': {
      const block = data.db.blocks.byId[action.blockId];
      if (!block) return;
      if (!block.header) {
        block.header = {};
      }
      block.header.showHeader = !block.header.showHeader;
      return;
    }
    case 'blocks.update.size': {
      const block = data.db.blocks.byId[action.blockId];
      if (!block) return;
      if (block.size === action.size) return;
      block.size = action.size || undefined;
      return;
    }
    case 'blocks.update.spacing': {
      const block = data.db.blocks.byId[action.blockId];
      if (!block) return;
      if (block.spacing === action.spacing) return;
      block.spacing = action.spacing || undefined;
      return;
    }
    case 'blocks.reorder': {
      const { blockCollectionLocation, fromIndex, toIndex } = action;
      const blockParent = getBlockIdsParent(blockCollectionLocation);
      blockParent.blockIds = arrayMove(
        blockParent.blockIds,
        fromIndex,
        toIndex,
      );
      return;
    }
    case 'blocks.replace': {
      const { blockId, initialData, newBlockType } = action;
      const block = data.db.blocks.byId[blockId];
      if (!block) return;

      data.db.blocks.byId[blockId] = {
        id: blockId,
        blockType: newBlockType,
        data: initialData, // clear out data of old type
        size: block.size, // keep size
      } as BlockConfig;

      return;
    }
    case 'blocks.delete': {
      const { blockId, blockCollectionLocation } = action;
      removeBlockFromContainer({
        blockId,
        container: getBlockIdsParent(blockCollectionLocation),
        data,
      });
      return;
    }
    case 'blocks.duplicate': {
      const { blockId, blockCollectionLocation } = action;
      const block = data.db.blocks.byId[blockId];
      if (!block) return;
      const newBlockId = makeShortId();
      const newBlock: BlockConfig = {
        ...block,
        id: newBlockId,
      };
      data.db.blocks.byId[newBlockId] = newBlock;
      const blockIdsParent = getBlockIdsParent(blockCollectionLocation);
      blockIdsParent.blockIds = blockIdsParent.blockIds.flatMap((b) => {
        if (b === blockId) {
          return [b, newBlockId];
        }
        return [b];
      });
      return;
    }
    case 'blocks.add': {
      const {
        blockCollectionLocation,
        blockType,
        isHeaderVisible,
        index,
        initialData,
        size,
      } = action;

      const blockConfig: Except<BlockConfig, 'blockType' | 'data'> = {
        id: makeShortId(),
        size: size || undefined,
        ...(isHeaderVisible ? { header: { showHeader: true } } : {}),
      };

      // we use `as` here b/c it's tough for TS to feel good about matching `blockType` with it's `data` type .... which is just an empty object to start for them all
      const block = {
        ...blockConfig,
        blockType,
        data: initialData || {},
      } as BlockConfig;

      const blockIdsParent = getBlockIdsParent(blockCollectionLocation);
      blockIdsParent.blockIds.splice(index, 0, block.id);
      data.db.blocks.byId[block.id] = block;
      trackEvent({
        type: 'Block Added',
        metadata: {
          blockType,
        },
      });
      return;
    }
    case 'blocks.moveToCollection': {
      const { blockId, from, to } = action;
      const blockIdsParentFrom = getBlockIdsParent(from);
      const blockIdsParentTo = getBlockIdsParent(to);
      blockIdsParentFrom.blockIds = blockIdsParentFrom.blockIds.filter(
        (bId) => bId !== blockId,
      );
      blockIdsParentTo.blockIds.push(blockId);
      // just being extra sure that IDs are unique
      blockIdsParentTo.blockIds = [...new Set(blockIdsParentTo.blockIds)];
      return;
    }
    case 'demos.add': {
      const { info, addToTemplate, userId } = action;
      const pattern = data.patternsState.patterns[info.patternId];
      const template = pattern.templates.find((t) => t.id === info.templateId);
      const date = now();
      switch (info.type) {
        case 'data': {
          const newDemo: DataDemo = {
            type: 'data',
            id: info.id || makeShortId(),
            patternId: info.patternId,
            templateId: info.templateId,
            title: info.title || 'New Variation',
            description: info.description || '',
            data: info.initialData || {
              props: {},
              slots: {},
            },
            meta: {
              createdBy: userId,
              createdDate: date,
              updatedBy: userId,
              updatedDate: date,
            },
          };
          data.db.demos.byId[newDemo.id] = newDemo;
          if (addToTemplate) {
            template.demoIds.push(newDemo.id);
          }
          trackEvent({
            type: 'Demo (data) Added',
            metadata: {
              patternId: info.patternId,
              templateLanguageId: template.templateLanguageId,
            },
          });
          return;
        }
        case 'template': {
          const newDemo: TemplateDemo = {
            type: 'template',
            id: info.id || makeShortId(),
            templateInfo: info.templateInfo,
            title: info.title || 'New Template',
            description: info.description || '',
            meta: {
              createdBy: userId,
              createdDate: date,
              updatedBy: userId,
              updatedDate: date,
            },
          };
          data.db.demos.byId[newDemo.id] = newDemo;
          if (addToTemplate) {
            template.demoIds.push(newDemo.id);
          }
          trackEvent({
            type: 'Demo (template) Added',
            metadata: {
              patternId: info.patternId,
              templateLanguageId: template.templateLanguageId,
            },
          });
          return;
        }
        default: {
          const _exhaustiveCheck: never = info;
        }
      }
      return;
    }
    case 'demos.duplicate': {
      // recursively duplicate
      const demo = data.db.demos.byId[action.demoId];
      const date = now();
      switch (demo.type) {
        case 'template': {
          data.db.demos.byId[action.newDemoId] = {
            ...demo,
            id: action.newDemoId,
            meta: {
              createdBy: action.userId,
              createdDate: date,
              updatedBy: action.userId,
              updatedDate: date,
            },
          };
          return;
        }
        case 'data': {
          const { newDemos } = duplicateDataDemo({
            demo,
            demosById: data.db.demos.byId,
            newDemoId: action.newDemoId,
            userId: action.userId,
          });
          Object.assign(data.db.demos.byId, newDemos);
          return;
        }
        default: {
          const _exhaustiveCheck: never = demo;
        }
      }
      return;
    }
    case 'demos.update':
      if (!data.db.demos.byId[action.demo.id]) {
        data.db.demos.byId[action.demo.id] = {
          ...action.demo,
          meta: {
            ...action.demo.meta,
            updatedBy: action.userId,
            updatedDate: now(),
          },
        };
        return;
      }
      mergeDeepInImmer({
        target: data.db.demos.byId[action.demo.id],
        source: {
          ...action.demo,
          meta: {
            ...action.demo.meta,
            updatedBy: action.userId,
            updatedDate: now(),
          },
        },
        sourceIsPartial: false,
      });
      return;
    case 'settings.update':
      mergeDeepInImmer({
        target: data.db.settings,
        source: action.settings,
        sourceIsPartial: true,
      });
      return;
    case 'settings.pages.update': {
      const { settings } = action;

      if (data.db.settings.pages === undefined) {
        data.db.settings.pages = {};
      }

      // Table of Contents
      if (settings.tableOfContents?.exclude?.length === 0) {
        delete settings.tableOfContents.exclude;
      }
      if (settings.tableOfContents?.hideByDefault === false) {
        delete settings.tableOfContents.hideByDefault;
      }
      if (Object.keys(settings.tableOfContents).length === 0) {
        delete settings.tableOfContents;
      }

      // If there are no settings, remove the key from the db
      if (Object.keys(settings).length === 0) {
        delete data.db.settings.pages;
        return;
      }

      mergeDeepInImmer({
        target: data.db.settings.pages,
        source: settings,
        sourceIsPartial: true,
      });
      return;
    }
    case 'settings.patterns.update': {
      const { settings } = action;
      if (data.db.settings.patterns === undefined) {
        data.db.settings.patterns = {};
      }
      if (settings.hideStatusSetsOnPages === false) {
        delete settings.hideStatusSetsOnPages;
      }
      // If there are no settings, remove the key from the db
      if (Object.keys(settings).length === 0) {
        delete data.db.settings.patterns;
        return;
      }
      mergeDeepInImmer({
        target: data.db.settings.patterns,
        source: settings,
        sourceIsPartial: true,
      });
      return;
    }
    case 'settings.theme.update':
      Object.assign(data.db.settings.theme, action.settings);
      return;
    case 'tokenSettings.theming.setEnabledRendererIds': {
      const { enabledRendererIds } = action;
      if (!data.db.settings.tokens) {
        data.db.settings.tokens = {};
      }
      if (!data.db.settings.tokens.theming) {
        data.db.settings.tokens.theming = {};
      }
      data.db.settings.tokens.theming.enabledRendererIds = enabledRendererIds;
      break;
    }
    default: {
      const _exhaustiveCheck: never = action;
    }
  }
}
