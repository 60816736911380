import { makeShortId, slugify } from '@knapsack/utils';

export function createUniqueFilename({
  filename,
}: {
  filename: string;
}): string {
  const ext = [...filename.split('.')].pop();
  const safeFilename = slugify({
    string: filename.substring(0, filename.lastIndexOf('.')) || filename,
  });
  return `${safeFilename}--${makeShortId()}.${ext}`;
}
